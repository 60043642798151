@import url('https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;600&display=swap');

.home-profile {
  color: $global-font-color;
  text-align: left !important;
  font-family: 'Inter', 'Inter Placeholder', sans-serif;
  max-width: 500px !important;
}

.home-profile-heading {
  font-size: 3rem;
  font-weight: 600;
  margin: 0;
  line-height: 1.2em !important;
}

.home-profile-subheading {
  font-size: 3reb;
  font-weight: 600;
  color: #858585;
  line-height: 1.2em !important;
}

@media screen and (max-width: 420px) {
  .home-profile-heading,
  .home-profile-subheading {
    font-size: 2rem;
  }
}

.home-profile-description {
  font-size: 1.125rem;
  font-weight: 500;
  color: #858585;
  margin: 1rem 0;
  font-family: 'Inter-Medium', 'Inter', sans-serif;
}

.home-profile-buttons {
  margin-top: 2rem;
  padding-left: 0 !important;
}

.home-profile-button {
  background-color: #333333;
  color: #e6edf3 !important;
  text-decoration: none;
  border-radius: 5px;
  font-size: 1.125rem;
  padding: 0.6rem 1.5rem;
  margin-right: 0.75rem;
  transition: all 0.3s ease;
}

.about-button {
  background-color: #333333;
  color: #e6edf3 !important;
  padding: 3px 8px;
  text-decoration: none;
  border-radius: 5px;
  margin-right: 10px;
  font-size: 14px;
  transition: all 0.3s ease;
}

.pick-my-brain-button {
  background-color: #1a8d4c;
  color: #ffffff !important;
  font-weight: 700;
  font-size: 1.125rem;
  padding: 0.1rem 0.4rem;
  border-radius: 4px;
  text-decoration: none;
  position: relative;
  overflow: hidden;
  transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1), 0 1px 3px rgba(0, 0, 0, 0.08);
  transform: translateY(0);
}

.pushable {
  position: relative;
  border: none;
  background: transparent;
  padding: 0;
  cursor: pointer;
  outline-offset: 4px;
  transition: filter 250ms;
}

.shadow {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 12px;
  background: #{'hsl(0deg 0% 0% / 0.25)'};
  will-change: transform;
  transform: translateY(2px);
  transition: transform 600ms cubic-bezier(.3, .7, .4, 1);
}

.edge {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 12px;
  background: linear-gradient(
    to left,
    #{'hsl(340deg 100% 16%) 0%'},
    #{'hsl(340deg 100% 32%) 8%'},
    #{'hsl(340deg 100% 32%) 92%'},
    #{'hsl(340deg 100% 16%) 100%'}
  );
}

.front {
  display: block;
  position: relative;
  padding: 12px 42px;
  border-radius: 12px;
  font-size: 1.25rem;
  color: white;
  background: #{'hsl(345deg 100% 47%)'};
  will-change: transform;
  transform: translateY(-4px);
  transition: transform 600ms cubic-bezier(.3, .7, .4, 1);
}

.pushable:hover {
  filter: brightness(110%);
}

.pushable:hover .front {
  transform: translateY(-6px);
  transition: transform 250ms cubic-bezier(.3, .7, .4, 1.5);
}

.pushable:active .front {
  transform: translateY(-2px);
  transition: transform 34ms;
}

.pushable:hover .shadow {
  transform: translateY(4px);
  transition: transform 250ms cubic-bezier(.3, .7, .4, 1.5);
}

.pushable:active .shadow {
  transform: translateY(1px);
  transition: transform 34ms;
}

.pushable:focus:not(:focus-visible) {
  outline: none;
}

@keyframes fadeInUp {
  0% {
      transform: translateY(50%);
      opacity: 0;
      filter: blur(5px);
  }
  100% {
      transform: translateY(0%);
      opacity: 1;
      filter: blur(0);
  }
}

.fadeInUp-animation-1 {
  animation: 0.6s fadeInUp cubic-bezier(0.25, 0.1, 0.25, 1) 0s;
  opacity: 0;
  animation-fill-mode: forwards;
}

.fadeInUp-animation-2 {
  animation: 0.6s fadeInUp cubic-bezier(0.25, 0.1, 0.25, 1) 0.15s;
  opacity: 0;
  animation-fill-mode: forwards;
}

.fadeInUp-animation-3 {
  animation: 0.6s fadeInUp cubic-bezier(0.25, 0.1, 0.25, 1) 0.3s;
  opacity: 0;
  animation-fill-mode: forwards;
}

.fadeInUp-animation-4 {
  animation: 0.6s fadeInUp cubic-bezier(0.25, 0.1, 0.25, 1) 0.45s;
  opacity: 0;
  animation-fill-mode: forwards;
}

#header-desktop {
  .header-wrapper {
    padding: 0 2em 0 2em !important;
  }
}

.home {
  max-width: 800px !important;
  margin: 0 auto;
  padding: 5rem 2rem 4rem;

  @media screen and (max-width: 768px) {
    padding: 3rem 1rem 4rem;
  }

  @media screen and (max-width: 480px) {
    padding: 3rem 0.5rem 4rem;
  }

  &.posts {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    gap: 2rem;

    article.single.summary {
      height: 100%;
      background: var(--card-background);
      border-radius: 8px;
      padding: 1.5rem;
      box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
      transition: transform 0.2s ease, box-shadow 0.2s ease;
      overflow: hidden;
      display: flex;
      flex-direction: column;

      &:hover {
        transform: translateY(-4px);
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.15);
      }

      .featured-image-preview {
        margin: -1.5rem -1.5rem 1.5rem;

        img {
          border-radius: 8px 8px 0 0;
        }
      }

      .single-title {
        font-size: 1.5rem;
        margin: 0.5rem 0;
      }

      .content {
        flex-grow: 1;
        font-size: 0.95rem;
      }

      .post-footer {
        margin-top: auto;
        padding-top: 1rem;
      }
    }
  }
}

.post-meta,
.single .post-meta,
.summary .post-meta,
.post-author,
.post-publish,
.post-date,
.post-category,
.post-series,
.post-word-count,
.post-reading-time {
  display: none !important;
}

[theme=dark] .home.posts article.single.summary {
  background: var(--card-background-dark, #2d2d2d);
}

.about-container {
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
  background-color: #f9f9f9;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  .pick-my-brain-button {
    display: inline-block;
    margin: 10px 5px;
  }
}

.about-header h1 {
  font-size: 2em;
  color: #333;
  text-align: center;
  margin-bottom: 20px;
}

.about-content {
  font-size: 1.1em;
  line-height: 1.6;
  color: #555;
}

.about-list {
  list-style-type: disc;
  margin-left: 20px;
  margin-bottom: 20px;
}

.about-footer {
  text-align: center;
  margin-top: 30px;
}

.about-footer h2 {
  font-size: 1.5em;
  color: #333;
  margin-bottom: 10px;
}

.social-links {
  list-style-type: none;
  padding: 0;
}

.social-links li {
  display: inline;
  margin: 0 10px;
}

.social-links a {
  color: #007bff;
  text-decoration: none;
}

.social-links a:hover {
  text-decoration: underline;
}

body {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='4' height='4' viewBox='0 0 4 4'%3E%3Cpath fill='%23698bab' fill-opacity='0.05' d='M1 3h1v1H1V3zm2-2h1v1H3V1z'%3E%3C/path%3E%3C/svg%3E");
}
